header {
    min-height: 270px; }

.col-1, .col-2 {
    display: block;
    width: 100%; }

.col-2 {
    margin-top: 15px; }

.block-1 .wrap-block {
    padding-bottom: 20px; }

header h1:first-child {
    padding-top: 25px; }

header h1:last-child {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 25px;
    line-height: 1.5em;
    z-index: 3;
    position: relative; }

.wrap-block {
    padding: 20px 20px 30px; }

ul {
    padding: 0 30px;
    font-size: 14px; }

.btn {
    height: 32px;
    line-height: 33px;
    font-size: 13px;
    padding: 0 20px;
    margin-top: 5px; }

.form-group {
    margin-bottom: 20px; }

body .form .block-1 h2 {
    padding: 20px 0 30px;
    font-size: 24px; }

.form-group label {
    font-size: 18px; }

.form-group .form-control {
    width: 100%;
    font-size: 16px;
    height: 32px; }

.form .content {
    padding: 0 15px; }

.success-block h1 {
    font-size: 34px; }

.block-1.bg-grey [class*=ic-] {
    width: 100%;
    padding-bottom: 15px;
    padding-right: 0;
    padding-left: 0; }

.block-1 .icon {
    font-size: 36px;
    line-height: 60px; }

[class*=ic-] p {
    padding-top: 10px;
    font-size: 14px;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding-left: 20px; }

.icon-box {
    display: inline-block;
    height: 60px;
    width: 60px;
    padding-bottom: 0;
    float: left; }

.block-2 h2 {
    font-size: 18px; }

ul li:before {
    font-size: 18px; }

.error-block h1 {
    font-size: 80px; }

.btn.viber-btn i {
    top: 3px;
    font-size: 17px; }
.lime {
    header {
        height: 529px;
        padding: 0;
        display: flex;
        align-items: center;
        box-shadow: rgba(2, 2, 2, 0.1) 0px 0px 0px 1000px inset;
        background-position: 0 50%;
        h1 {
            font-size: 32px; } }
    .content {
        margin: 0 auto; } }
