@mixin placeholder {
    &::-webkit-input-placeholder {
        @content; }
    &:-moz-placeholder {
        @content; }
    &::-moz-placeholder {
        @content; }
    &:-ms-input-placeholder {
        @content; } }

@include placeholder {
    ffont-size: 16px;
    color: #ccc; }

.form-block {
    min-height: 320px; }

.form {
    overflow-x: hidden;
    min-height: 100%; }

.form-group {
    margin-bottom: 35px;
    position: relative; }

.form-group label, .field-group-label {
    margin-bottom: 8px;
    display: block;
    font-size: 20px;
    color: #000; }

.form .block-1 h2 {
    padding-left: 0;
    font-weight: 500;
    padding-top: 20px;
    color: #000;
    text-align: center;
    font-size: 32px;
    padding-bottom: 40px; }

.form.blue,
.form.iceberg {
    .block-1 {
        padding-top: 0; } }

.ant-input {
    position: relative;
    display: inline-block;
    padding: 4px 7px;
    width: 100%;
    height: 28px;
    font-size: 12px;
    line-height: 1.5;
    color: rgba(0,0,0,.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s; }

.form-group .form-control {
    padding: 0 10px;
    font-size: 20px;
    outline: none;
    width: 400px;
    height: 43px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #dddede; }

.form-group label.radio {
    padding-left: 30px;
    font-size: 16px; }

.content {
    background-color: #fff;
    position: relative;
    max-width: 1140px;
    margin: -120px auto 0;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.12); }

.form .content {
    position: relative;
    background-color: #f6f7f9; }

.btn-file {
    display: inline-block;
    position: relative; }

.btn-sm {
    padding: 0 15px;
    height: 32px;
    line-height: 32px;
    font-size: 13px;
    margin-right: 10px; }

.btn-link {
    display: block;
    margin-bottom: 20px;
    text-align: center; }

.upload-btn input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    right: 0;
    bottom: 0;
    font-size: 30px; }

input[type=file] {
    display: block; }

.form .control .btn {
    border: 0;
    margin: 30px 0 20px; }

.checkbox-inline {
    font-size: 16px;
    color: #000; }

.checkbox-inline a {
    color: #000;
    text-decoration: underline; }

.date-picker {
    width: 100%; }

.date-picker input.date-masked-input {
    border: none;
    height: 39px;
    font-size: 20px;
    padding: 0; }

.date-picker input.ant-calendar-picker-input {
    display: inline-block; }

.date-picker .date-calendar {
    right: 0;
    left: auto;
    bottom: 0; }

.date-picker .ant-calendar-picker-icon {
    margin-top: -32px; }

.ant-select-selection__clear {
    right: 20px; }

.ant-calendar-picker > div,
.ant-calendar-picker-input,
.ant-select-selection,
.ant-select-selection__rendered {
    height: 100%;
    border: none;
    margin: 0;
    outline: none;
    box-shadow: none!important; }

.ant-input:focus,
.ant-select-open .ant-select-selection,
.ant-select-selection:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0; }

.ant-radio + span {
    margin-left: 0; }

.ant-select-selection-selected-value {
    margin-top: 5px; }

.ant-select-arrow {
    right: 3px; }

.ant-calendar-picker-input.ant-input {
    padding: 0; }

.ant-calendar-picker-input {
    font-size: 16px; }

.error.form-group > .form-control,
.error.form-group > .upload-brn,
.error.form-group .ant-radio-inner,
.error.form-group .ant-checkbox-inner {
    border: 1px solid #FF5454; }


.form-group .ant-checkbox-wrapper {
    width: auto!important;
    margin-right: 0 !important;
    font-size: 16px !important;
    display: inline-block; }

.checkbox-group {
    padding-left: 30px; }

.form-group .checkbox-group .ant-checkbox-wrapper {
    display: block; }

.checkbox-group .ant-checkbox-wrapper span:not(.ant-checkbox):not(.ant-checkbox-inner) {
    padding-left: 15px;
    padding-right: 25px; }

.opd-modal {
    font-size: 16px;
    padding-bottom: 30px; }

.opd-modal p {
    margin: 15px 0; }

.opd-modal ul {
    list-style-type: none;
    margin-left: 40px; }

.opd-modal ol {
    list-style-type: decimal;
    margin-left: 18px; }

.opd-modal ol > li {
    margin-bottom: 10px; }

.ant-modal-title {
    font-size: 18px;
    line-height: 30px; }

.ant-modal-header {
    padding-right: 40px; }

.error-msg, .error-message {
    color: #FF4040;
    font-size: 14px;
    margin-top: 5px; }

.ant-radio-group {
    position: relative;
    margin-bottom: 5px; }

.ant-radio-group .webpage-input .error-msg {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 300px; }

.ant-calendar-picker-input {
    border: none;
    height: 100%; }

.opd label + span {
    margin-left: 10px; }

.opd-link {
    text-decoration: underline;
    margin-left: 5px;
    color: inherit;
    cursor: pointer; }

.opd-content {
    word-wrap: break-word;
    ul {
        list-style-type: disc; } }

.link {
    position: absolute;
    top: 0;
    line-height: 0;
    height: 0;
    display: inline; }

.ant-calendar-picker-container .ant-calendar {
    top: 6px;
    right: 11px; }

.error-input {
    .ant-input:not(.ant-calendar-picker-input),
    .ant-select,
    .ant-calendar-picker {
        border: 1px solid #ff5454; } }

.ant-spin {
    &.main-spinner {
        position: fixed;
        top: calc(50% - 12px);
        left: calc(50% - 12px);

        &.theme-spinner {
            left: 0;
            top: 100px; } } }

.error-msg {
    color: #ff4040;
    font-size: 14px;
    position: absolute;
    bottom: -25px;
    left: 0; }

.ant-select-dropdown-menu li:first-child {
   border-top-left-radius: 4px;
   border-top-right-radius: 4px; }

.vacancy-route {
    height: 100%; }

.datepicker {
    width: 400px;
    &:after {
        content: '';
        display: table;
        clear: both; }
    .form-control:first-child {
        margin-left: 0; }
    .day {
        width: 20% !important;
        float: left;
        border: 1px solid #dddede !important;
        &.error {
            border: 1px solid #ff5454 !important; } }
    .month {
        width: calc(50% - 10px) !important;
        margin-left: 10px;
        float: left;
        &.error {
            border: 1px solid #ff5454; }
        .ant-select-selection-selected-value {
            margin-top: 5px; } }
    .year {
        width: calc(30% - 10px) !important;
        margin-left: 10px;
        float: left;
        border: 1px solid #dddede !important;
        &.error {
            border: 1px solid #ff5454 !important; } } }

.monthpicker {
    width: 400px;
    &:after {
        content: '';
        display: table;
        clear: both; }
    .month {
        width: 70% !important;
        float: left;
        &.error {
            border: 1px solid #ff5454; }
        .ant-select-selection-selected-value {
            margin-top: 5px; } }
    .year {
        width: calc(30% - 10px) !important;
        margin-left: 10px;
        float: left;
        border: 1px solid #dddede !important;
        &.error {
            border: 1px solid #ff5454 !important; } } }

.ancor.form {
    background: #fff;
    .content {
        padding: 0 0 20px;
        max-width: 1020px;
        box-shadow: none;
        background: transparent; }
    header {
        overflow: hidden;
        .img-bg {
            background-position: top; } }
    .control .btn {
        margin: 30px 0 20px;
        background-color: rgb(255, 65, 54);
        &:hover {
            background-color: rgb(230, 43, 32); } }
    .wrap-block {
        padding: 40px 70px 20px;
        h2 {
            font-size: 24px !important;
            text-align: left;
            font-weight: 500;
            color: #e50000; } }
    .content {
        position: relative; }
    .form-group {
        margin-bottom: 35px;
        label {
            margin-bottom: 10px;
            display: block;
            font-size: 18px;
            color: #000;
            &.radio {
                padding-left: 30px;
                font-size: 16px;
                input {
                    margin-right: 10px; } } }
        .inline-from-input {
            display: inline;
            .form-control {
                width: 170px;
                height: 24px;
                border-radius: 9.5px;
                margin: 0 8px; }
            label {
                font-size: 16px;
                display: inline;
                position: relative;
                top: -2px; } }
        .radio-inline {
            display: inline-block; }
        .form-control {
            padding: 0 10px;
            font-size: 20px;
            outline: none;
            max-width: 400px;
            height: 43px;
            background-color: #ffffff;
            border: 2px solid rgb(228, 228, 240)!important;
            border-radius: 0;
            &:focus,&.ant-select-focused {
                border-color: #000!important; } } }
    .checkbox-inline {
        font-size: 16px;
        color: #000;
        span {
            text-decoration: underline; }
        input {
            width: 16px;
            height: 16px; } }
    .ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner,.ant-radio-inner:after {
        background-color: rgb(255, 65, 54);
        border-color: rgb(255, 65, 54); }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,.ant-checkbox-checked:after,.ant-radio-checked .ant-radio-inner,.ant-radio-checked:after,.ant-radio-wrapper:hover .ant-radio .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-focused .ant-radio-inner {
        border-color: rgb(255, 65, 54); } }

.ancor.wrap.success {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #f54d2e;
    color: #ffffff;
    &:before {
        display: none; }
    .success-block {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        h1 {
            padding-top: 20px;
            font-size: 45px;
            font-weight: 500;
            color: #f5f7f8; }
        h2 {
            font-size: 24px;
            margin-bottom: 30px;
            font-weight: 500;
            color: #e50000; } } }

.composte-field {
    .form-group {
        margin-bottom: 25px; } }

.composite-group-label {
    text-align: center; }

.composite-remove-button, .composite-add-button {
    margin-bottom: 20px;
    margin-top: 5px; }

@media screen and (max-width: 1140px) {
    .wrap:not(.lime) {
        padding-bottom: 0; }
    .wrap.form {
        background-color: #f6f7f9; }
    .content {
        box-shadow: none; } }

@media screen and (max-width: 767px) {
    header {
        min-height: 270px; }

    .col-1, .col-2 {
        display: block;
        width: 100%; }

    .col-2 {
        margin-top: 15px; }

    .btn {
        height: 32px;
        line-height: 33px;
        font-size: 13px;
        padding: 0 20px;
        margin-top: 5px; }

    .btn:focus {
        border-radius: 30px; }

    .form-group {
        margin-bottom: 20px; }

    .form-group label {
        font-size: 18px; }

    .form-group .form-control {
        width: 100%;
        font-size: 16px;
        height: 32px;

        &.ant-select {
            .ant-select-selector {
                .ant-select-selection-item {
                    line-height: 30px; }
                .ant-select-selection-search {
                    line-height: 30px; } } } }

    .form .content {
        padding: 0; }

    .success-block h1 {
        font-size: 34px; }

    body .block-1.bg-grey [class*=ic-] {
        width: 100%;
        padding-bottom: 15px;
        padding-right: 0;
        padding-left: 0; }

    .block-1 .icon {
        font-size: 36px;
        line-height: 60px; }

    [class*=ic-] p {
        padding-top: 0;
        font-size: 14px;
        display: block;
        overflow: hidden;
        vertical-align: middle;
        text-align: left;
        padding-left: 20px; }

    [class*=ic-] p:first-child {
        padding-top: 10px; }

    .icon-box {
        display: inline-block;
        height: 60px;
        width: 60px;
        padding-bottom: 0;
        float: left; }
    .block-2 h2 {
        font-size: 18px; }
    ul li:before {
        font-size: 18px; }
    .error-block h1 {
        font-size: 80px; }
    .btn.viber-btn i {
        top: 3px;
        font-size: 17px; }
    .blue .block-1 .btn.viber-btn,
    .iceberg .block-1 .btn.viber-btn {
        margin-left: 10px; }
    .ant-select-selection-selected-value {
        margin-top: 0!important; }
    .datepicker {
        width: 100%; }
    .datepicker .year {
        width: calc(25% - 5px) !important;
        margin-left: 5px; }
    .datepicker .month {
        width: calc(55% - 5px) !important;
        margin-left: 5px; }
    .checkbox-group .ant-checkbox-wrapper span:not(.ant-checkbox):not(.ant-checkbox-inner) {
        padding-left: 8px; }
    .form-group label.radio,.checkbox-group {
        padding-left: 15px; }
    .ant-radio-wrapper {
        white-space: normal; }
    .ant-radio {
        padding-top: 5px; }
    .radio.ant-radio-wrapper span {
        display: table-cell;
        min-width: 16px;
        vertical-align: top; }
    .ancor.form {
        header .row, header .box-1 {
            display: block; }
        header .box-1 {
            width: 100%; }
        header .parallelogram {
            display: block;
            width: 100%; }
        header .parallelogram-container {
            &:before {
                display: none; }
            .parallelogram-content {
                text-align: center;
                max-width: inherit;
                & > div {
                    width: 100%; } } }
        header .img-bg {
            width: 100%;
            background-position: top;
            min-height: 200px;
            &:before {
                content: '';
                background-color: rgba(0, 0, 0, 0.38);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0; } }
        .wrap-block {
            padding: 40px 20px 20px; } } }

::-webkit-file-upload-button {
    cursor: pointer; }

.form-group.error-input .datepicker .ant-select:not(.error) {
    border: 1px solid #dddede; }

.form-control.ant-select {
    padding: 0;
    &.ant-select-open {
        .ant-select-arrow {
            transform: rotate(180deg); } }
    &.ant-select-focused {
        box-shadow: none;
        border-color: #ffffff;
        .ant-select-selector {
            box-shadow: none; } }
    .ant-select-selector {
        border-radius: 10px;
        height: 100%;
        border: none;
        .ant-select-selection-item {
            line-height: 41px;
            color: rgba(0, 0, 0, 0.65);
            opacity: 1; }
        .ant-select-selection-search {
            height: 100%;
            line-height: 41px; } }
    .ant-select-arrow {
        transition-duration: .3s;
        right: 13px; }
    .ant-select-clear {
        right: 28px; } }

body div.ant-select-dropdown {
    padding: 0; }
