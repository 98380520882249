.template-wrap {
  min-width: 980px;
  max-width: 1000px;
  margin: 0 auto 20px;
  h1 {
    font-weight: normal;
    text-align: center;
    margin: 20px 0;
    small {
      color: #9e9e9e;
      font-weight: 100;
      font-size: 60%; } }
  h2 {
    margin-bottom: 10px;
    font-size: 1.1rem !important;
    text-transform: uppercase;
    color: #72848c;
    line-height: 1.6;
    font-weight: normal; }
  .description {
    font-size: .9rem;
    color: #747373; }
  .template-col {
    display: inline-block;
    width: 50%;
    &-item {
      display: flex;
      padding: 20px 15px;
      width: 465px;
      min-height: 680px;
      margin: 10px auto;
      background-color: #fff;
      border-radius: 0.4rem;
      box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06);
      transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
      &:hover,&:focus {
        box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12); }
      &:hover {
        .preview-img {
          background-position: center 100%;
          transition: background-position .5s cubic-bezier(0, 0.1, 0.1, 0); } } } }
  .themes-title {
    text-align: center;
    margin: 10px; }
  .template {
    &-default {
      .preview-img {
        background-image: url("../assets/img/preview/default.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/default-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/default-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/default-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/default-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/default-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/default-6.png"); } } }
    &-red {
      .preview-img {
        background-image: url("../assets/img/preview/red.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/red-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/red-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/red-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/red-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/red-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/red-6.png"); } }
      &:hover {
        .preview-img {
          transition-duration: 1s; } } }
    &-green {
      .preview-img {
        background-image: url("../assets/img/preview/green.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/green-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/green-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/green-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/green-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/green-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/green-6.png"); } } }
    &-blue {
      .preview-img {
        background-image: url("../assets/img/preview/blue.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/blue-6.png"); } }
      &:hover {
        .preview-img {
          transition-duration: 4s; } } }
    &-ancor {
      .preview-img {
        background-image: url("../assets/img/preview/ancor.png"); } }
    &-gray {
      .preview-img {
        background-image: url("../assets/img/preview/gray.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/gray-6.png"); } }
      &:hover {
        .preview-img {
          transition-duration: 1s; } } }
    &-lime {
      .preview-img {
        background-image: url("../assets/img/preview/lime.png"); }
      &.theme-1 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-1.png"); } }
      &.theme-2 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-2.png"); } }
      &.theme-3 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-3.png"); } }
      &.theme-4 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-4.png"); } }
      &.theme-5 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-5.png"); } }
      &.theme-6 {
        .preview-img {
            background-image: url("../assets/img/preview/lime-6.png"); } }
      &:hover {
        .preview-img {
          transition-duration: 3s; } } }
    &-iceberg {
      .preview-img {
        background-image: url("../assets/img/preview/iceberg.png"); } } }
  .controls {
    .button {
      &:last-child {
        margin: 0; } } } }

.themes {
    .themes-title {
        text-align: center;
        margin-bottom: 10px; } }

.themes-select {
    margin-bottom: 10px;

    .modal-template & {
       margin-top: 20px; }

    .theme-img {
        width: 80px;
        height: 80px;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        margin-left: 15px;
        position: relative;
        border: 2px solid #e4e4e4;

        .modal-template & {
          width: 24%;
          height: 130px; }
        &.selected {
            border: 2px solid #2d89db;
            &:before {
                content: '';
                display: inline-block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.35);
                position: absolute; }
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 30px;
                height: 30px;
                margin: -15px 0 0 -15px;
                background-size: 30px;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMnB4IiBoZWlnaHQ9IjIycHgiIHZpZXdCb3g9IjAgMCAyMiAyMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29uZmluZGVyX2NoZWNrLWNpcmNsZV8zMzI1MDc3PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8ZyBpZD0iaWNvbmZpbmRlcl9jaGVjay1jaXJjbGVfMzMyNTA3NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIDAuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yMCwxMC4wOCBMMjAsMTEgQzE5Ljk5NzQ2NzgsMTUuNDI4Njg1OSAxNy4wODIyOTQsMTkuMzI4MjEzIDEyLjgzNTM1MjQsMjAuNTgzOTAxIEM4LjU4ODQxMDg2LDIxLjgzOTU4OSA0LjAyMTM5MzU1LDIwLjE1MjMxMjEgMS42MTA5NTUwOSwxNi40MzcwNjYzIEMtMC43OTk0ODMzNzYsMTIuNzIxODIwNSAtMC40NzkxMzY1NTQsNy44NjM2Mzg5OCAyLjM5ODI3NDE5LDQuNDk3MDcyMTQgQzUuMjc1Njg0OTQsMS4xMzA1MDUzMSAxMC4wMjQ3MTI2LDAuMDU3NTI1Mjg0MiAxNC4wNywxLjg2IiBpZD0iUGF0aCI+PC9wYXRoPiAgICAgICAgICAgIDxwb2x5bGluZSBpZD0iUGF0aCIgcG9pbnRzPSIyMCAzIDEwIDEzLjAxIDcgMTAuMDEiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4='); } } }
    .theme-img:last-child {
        margin-right: 0; }
    .theme-img:hover {
        cursor: pointer; }
    .theme-img-ancor {
        &.theme-img-default {
          background-image: url("../assets/img/preview/ancor.png"); } }
    .theme-img-default {
        background-image: url("../assets/img/preview/default.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/default-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/default-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/default-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/default-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/default-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/default-6.png"); } }
    .theme-img-red {
        background-image: url("../assets/img/preview/red.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/red-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/red-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/red-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/red-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/red-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/red-6.png"); } }
    .theme-img-green {
        background-image: url("../assets/img/preview/green.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/green-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/green-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/green-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/green-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/green-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/green-6.png"); } }
    .theme-img-blue {
        background-image: url("../assets/img/preview/blue.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/blue-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/blue-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/blue-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/blue-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/blue-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/blue-6.png"); } }
    .theme-img-gray {
        background-image: url("../assets/img/preview/gray.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/gray-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/gray-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/gray-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/gray-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/gray-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/gray-6.png"); } }
    .theme-img-lime {
        background-image: url("../assets/img/preview/lime.png");
        &.theme-img-1 {
            background-image: url("../assets/img/preview/lime-1.png"); }
        &.theme-img-2 {
            background-image: url("../assets/img/preview/lime-2.png"); }
        &.theme-img-3 {
            background-image: url("../assets/img/preview/lime-3.png"); }
        &.theme-img-4 {
            background-image: url("../assets/img/preview/lime-4.png"); }
        &.theme-img-5 {
            background-image: url("../assets/img/preview/lime-5.png"); }
        &.theme-img-6 {
            background-image: url("../assets/img/preview/lime-6.png"); } }
    .theme-img-iceberg {
        background-image: url("../assets/img/preview/iceberg.png"); } }
