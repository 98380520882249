* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

@import 'font';
@import 'icons';
@import '~react-confirm-alert/src/react-confirm-alert.css';

.form-group label {
  line-height: 1.5; }

body {
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  background-color: #eef5f9;
  -webkit-font-smoothing: antialiased; }

html,
body,
#root {
  height: 100%; }

.wrap:not(.lime) {
  padding-bottom: 40px; }
.wrap {
  &-block {
    position: relative;
    margin: 0 auto;
    max-width: 1140px;
    padding: 48px 98px 40px; } }

header {
  padding: 0 30px 130px;
  box-sizing: border-box;
  min-height: 450px;
  display: table;
  width: 100%;
  background-color: rgba(29, 155, 212, 0.83);
  background-size: cover;
  background-position: center;
  box-shadow: 0.6px 2.9px 32.9px 2.1px rgba(0,0,0,.2);
  .btn {
    position: relative;
    display: inline-block;
    &-main-container {
      display: flex;
      align-items: center;
      justify-content: center; } } }

.header-content {
  display: table-cell;
  vertical-align: middle;
  position: relative; }

.block-1 {
  [class*="ic-"] {
    width: 33%;
    display: inline-block;
    float: left; }
  [class*="ic-"]:first-child {
    padding-right: 40px; }
  [class*="ic-"]:nth-child(2) {
    padding: 0 40px; }
  [class*="ic-"]:last-child {
    padding-left: 40px; }
  [class*="ic-"] .text {
    font-size: 15px; }
  .icon {
    color: #07355e;
    font-size: 64px;
    line-height: 120px; } }

.icon {
  display: inline-block;
  background-repeat: no-repeat; }

.btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 40px;
  display: inline-block;
  line-height: 56px;
  height: 56px;
  border-radius: 30px;
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.14);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  transition: all .3s;
  &:active {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.2); }
  &:hover {
    cursor: pointer;
    text-decoration: none; } }

button:focus {
  outline: none; }

.text-center {
  text-align: center; }

.bg {
  background-color: #07355E;
  opacity: 0.74;
  height: 450px;
  width: 100%;
  top: 0;
  display: block;
  position: absolute; }

.controls-main {
  text-align: center;
  background-color: #FAFAFA; }

.bg-grey {
  background-color: rgba(108, 154, 227, 0.05);
  border-bottom: 1px solid rgba(0,0,0,0.03); }

.default {
  footer {
    color: #fff;
    .col-1 {
      padding-right: 20px;
      font-size: 1.2em; }
    .col-1 p:first-child {
      font-size: 22px;
      margin-bottom: 15px; }
    .col-1 p:last-child {
      font-size: 18px; } } }

.block-1 .wrap-block {
  padding-bottom: 50px; }

.col-1, .col-2 {
  width: 50%;
  display: inline-block;
  float: left; }

.row:before, .row:after {
  display: table;
  content: ''; }

.row:after {
  clear: both; }

.wrap {
  &.success {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #2c89db; }
  &.error {
    display: table;
    width: 100%;
    height: 100%;
    background-color: rgba(7, 53, 94, 0.73); } }

.success-block,
.error-block {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.success-block h1 {
  padding-top: 20px;
  font-size: 45px;
  font-weight: 500;
  color: #fff; }

.error-block {
  h1 {
    font-size: 150px;
    color: #fff;
    text-shadow: 1px 1px 8px rgba(82, 82, 82, 0.38); }
  h2 {
    color: #fff;
    margin-bottom: 30px;
    &.with-cursor {
      cursor: pointer; } }
  ul {
    list-style-type: disc; }
  .stab {
    text-align: left; } }

ul li {
  position: relative; }

ul li::before, .ql-editor ul > li:before {
  font-family: icon!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 5px;
  margin-left: 0 !important;
  color: #1d9bd4;
  font-size: 20px;
  display: inline-block;
  padding-right: 8px;
  position: absolute;
  left: -35px;
  top: 2px; }

.form-group {
  margin-bottom: 35px;
  label {
    margin-bottom: 8px;
    display: block;
    font-size: 20px;
    color: #000; } }

.ant-input {
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  width: 100%;
  height: 28px;
  font-size: 12px;
  line-height: 1.5;
  color: rgba(0,0,0,.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }

.form-group {
  .form-control {
    padding: 0 10px;
    font-size: 20px;
    outline: none;
    width: 400px;
    height: 43px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #dddede; }
  label.radio {
    padding-left: 30px;
    font-size: 16px; } }

.content {
  background-color: #fff;
  position: relative;
  max-width: 1040px;
  margin: -120px auto 0;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.12); }

.btn-file {
  display: inline-block;
  position: relative; }

.btn-sm {
  padding: 0 15px;
  height: 32px;
  line-height: 32px;
  font-size: 13px;
  margin-right: 10px; }

.upload-btn input[type=file] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  right: 0;
  bottom: 0;
  font-size: 30px; }

input[type=file] {
  display: block; }

.form .control .btn {
  border: 0;
  margin: 30px 0 20px; }

.checkbox-inline {
  font-size: 16px;
  color: #000; }

.checkbox-inline a {
  color: #000;
  text-decoration: underline; }

.date-picker {
  width: 100%;
  input.date-masked-input {
    border: none;
    height: 39px;
    font-size: 20px;
    padding: 0; }
  input.ant-calendar-picker-input {
    display: inline-block; }
  .date-calendar {
    right: 0;
    left: auto;
    bottom: 0; }
  .ant-calendar-picker-icon {
    margin-top: -32px; } }

.ant-modal {
  color: #000; }

.ant-calendar-picker > div,
.ant-calendar-picker-input,
.ant-select-selection,
.ant-select-selection__rendered {
  height: 100%;
  border: none; }

.ant-input:focus,
.ant-select-open .ant-select-selection,
.ant-select-selection:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.ant-radio + span {
  margin-left: 10px; }

.ant-select-selection-selected-value {
  margin-top: 10px;
  .select-description {
    display: none; } }

.ant-calendar-picker-input.ant-input {
  padding: 0; }

.ant-calendar-picker-input {
  font-size: 16px; }

.error.form-group > .form-control,
.error.form-group > .upload-brn,
.error.form-group .ant-radio-inner,
.error.form-group .ant-checkbox-inner {
  border: 1px solid #FF5454; }

.ant-checkbox-wrapper {
  width: auto!important;
  display: inline-block !important;
  margin-right: 0 !important; }

.opd-modal {
  font-size: 16px;
  padding-bottom: 30px;
  p {
    margin: 15px 0; }
  ul {
    list-style-type: disc;
    margin-left: 40px; }
  ol {
    list-style-type: decimal;
    margin-left: 18px;
    & > li {
      margin-bottom: 10px; } } }

.ant-modal-title {
  font-size: 18px;
  line-height: 30px; }

.ant-modal-header {
  padding-right: 40px; }

.error-msg {
  color: #FF4040;
  font-size: 14px;
  margin-top: 5px; }

.ant-radio-group {
  position: relative;
  margin-bottom: 5px;
  .webpage-input .error-msg {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 300px; } }

.edit-mode, .preview-mode {
  .ql-container {
    font-family: inherit; } }

.preview-mode {
  text-align: left;
  overflow-y: auto;
  margin: auto;
  background: #fff;
  &.iphone,
  &.ipad {
    margin-top: 30px;
    overflow-y: hidden; }
  .wrap {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, .05); } }

.image-loader {
  position: relative;
  input {
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
    &:focus {
      outline: none; } } }

.ql-align-center {
  text-align: center; }

.ql-align-right {
  text-align: right; }

.ql-align-justify {
  text-align: justify; }

.ql-tooltip[data-mode=link] {
  input {
    left: 0; } }

.icon-box {
  width: 100px;
  height: 100px;
  margin: 0 auto 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.settings {
  position: relative;
  &-sidebar {
    position: fixed;
    top: 0;
    left: -300px;
    height: 100%;
    width: 300px;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    z-index: 1002;
    padding: 30px 15px;
    transition: left .3s;
    &.opened {
      left: 0; }
    .ant-checkbox-wrapper {
      font-size: 14px !important; } }
  .settings-btn {
    position: absolute;
    display: none;
    top: 15px;
    right: 15px;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    cursor: pointer;
    z-index: 3;
    transition: all .3s;
    &:focus {
      outline: none; }
    &:hover {
      background: #efefef; }
    &.settings-button-footer {
      bottom: 15px;
      top: initial;
      color:rgba(0, 0, 0, 0.65)!important {} }
    .fa-cog {
      font-size: 16px;
      margin-top: 4px; } }
  &:hover,
  &.opened {
    .settings-btn {
      display: inline-block; } } }

.wrap:hover {
  .settings-btn {
      display: inline-block; } }

.settings.settings-header {
  .settings-btn.settings-btn-header {
    position: fixed;
    top: 76px;
    left: 10px; } }

.color-picker {
  position: relative;
  float: right;
  &-swatch {
    padding: 5px;
    background: white;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    cursor: pointer;
    display: inline-block;
    margin: 0 0 5px;
    &-color {
      width: 36px;
      height: 14px;
      border-radius: 2px; } }
  &-popover {
    position: absolute;
    z-index: 1001;
    top: 35px;
    right: 0; } }

.cover {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001; }

.text-input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 8px;
  margin: 8px 0;
  font-size: 14px;
  &:focus {
    outline: none; } }

.image-settings-upload {
  margin: 8px 0;
  &-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 10px;
    font-size: 14px;
    background: #008ED6;
    border-radius: 1px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    color: white; }
  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer; } }

.uploaded-image-container {
  margin: 8px 0;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle; }
  & button {
    border: none;
    font-size: 18px;
    color: #444;
    margin-left: 2px;
    padding: 0 5px;
    vertical-align: middle;
    cursor: pointer;
    &:focus {
      outline: none; } }
  img {
    max-width: 180px;
    max-height: 150px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle; } }

.favicon-loader {
  .uploaded-image-container {
    img {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      border: 5px solid #fff;
      box-sizing: content-box;
      width: 16px;
      height: 16px; } } }

.uploaded-image-edit-btn {
  position: relative;
  overflow: hidden;
  display: inline-table;
  width: 32px;
  height: 32px;
  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
    font-size: 0;
    &:hover {
      & + button {
        color: #40a9ff; } } } }

.settings-label {
  display: inline-block;
  font-size: 12px;
  color: #333;
  font-weight: bold; }

.settings-sidebar {
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
  background: #fff;
  text-align: left;
  h1 {
    position: relative;
    font-size: 22px !important;
    color: #fff;
    background-color: #2d89db;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.15);
    padding: 0 15px;
    height: 56px;
    line-height: 56px; }
  .close-btn {
    color: #fff;
    background: transparent;
    border: 0;
    font-size: 1.2em;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -28px;
    cursor: pointer;
    outline: none; } }

.setting-body {
  overflow: auto;
  height: calc(100% - 55px);
  padding: 20px 15px 30px; }

.setting-group {
  border-bottom: 1px dashed #e4e3e3;
  margin-bottom: 10px;
  &:before,&:after {
    content: '';
    display: table;
    clear: both; }
  &:last-child {
    border: 0; } }

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  margin-bottom: 0; }

.ql-editor {
  padding: 0 !important;
  overflow: visible !important;
  line-height: 1.4;
  &:focus {
    outline: none; } }

p {
  font-size: 15px; }
h1 {
  font-size: 36px; }
h2 {
  font-size: 32px; }
h3 {
  font-size: 30px; }
h4 {
  font-size: 28px; }
h5 {
  font-size: 26px; }
h6 {
  font-size: 24px; }
ul, ol {
  padding-left: 40px !important;
  font-size: 15px;
  line-height: 1.7em; }
ul {
  list-style-type: none; }
li {
  padding-left: 0 !important; }
a {
  text-decoration: underline;
  &:hover {
    text-decoration: underline; } }

.ql-tooltip {
  z-index: 4000; }

.bullet-settings {
  position: relative;
  float: right;
  &-toggle {
    margin-bottom: 12px;
    font-size: 20px;
    background: none;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none; } }
  &-options {
    position: absolute;
    top: 34px;
    right: 0;
    width: 204px;
    background: #fff;
    z-index: 1001;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
    border-radius: 4px;
    button {
      padding: 7px;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 20px;
      &:focus {
        outline: none; } } } }

.settings-toolbar-height {
  height: 56px; }

.settings-toolbar {
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.15);
  background-color: #fff;
  padding: 0 20px;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  &-button {
    vertical-align: top;
    height: 100%;
    padding: 0 15px;
    background: none;
    border: none;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      color: #2c89db;
      background: darken(#fff, 5%); } }
  .toolbar-select {
    display: inline-block;
    width: 250px;
    margin-right: 15px;
    .ant-select-selection-selected-value {
      margin-top: 0; } }
  .toolbar-content {
    text-align: center;
    max-width: 1040px;
    margin: 0 auto;
    height: 100%;
    .toolbar-left,.toolbar-center,.toolbar-right {
      display: inline-block; }
    .toolbar-left {
      float: left;
      height: 100%; }
    .toolbar-right {
      float: right;
      height: 100%; }
    .toolbar-center {
      height: 100%;
      .toolbar-select {
        margin-top: 12px;
        .ant-select-selection {
          border: 1px solid #eee; } } } }
  h1 {
    display: inline-block;
    font-size: 22px;
    margin-right: 40px;
    color: #fff; }
  a {
    color: #fff;
    line-height: 34px; } }

.button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  color: #fff;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  border-radius: 30px;
  border: 0;
  background-color: #2c89db;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
  margin-right: 15px;
  &:active {
    box-shadow: inset 0 -2px 3px 0 rgba(0,0,0,0.1); }
  &:hover {
    cursor: pointer;
    background-color: #207ed2; }
  &-lg {
    height: 50px;
    line-height: 50px;
    font-size: 16px; }
  &-white {
    background-color: #fff;
    color: #444;
    border: 1px solid #a7a7a7;
    &:hover {
      background-color: #f9f9f9;
      color: #000; } } }

.red {
  header {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(64,64,64,.3); }
    h1 {
      text-shadow: 1px 1px 5px rgba(0,0,0,.65); } }
  .btn {
    box-shadow: 0 5px 10px rgba(0,0,0,.2); }
  .block-1 {
    padding: 40px 7.3% 25px; }
  .block-2 {
    padding: 40px 7.3% 60px;
    .controls {
      text-align: center;
      margin: 30px 0; }
    h5 {
      font-size: 24px !important; }
    h6 {
      margin-top: 40px;
      font-size: 22px!important; } }
  p {
    font-size: 18px; }
  ul, ol {
    font-size: 18px;
    li {
      padding-bottom: 10px; } } }

.green {
  header {
    h1 {
      text-shadow: 1px 1px 5px rgba(0,0,0,.5); } }
  .btn {
    border-radius: 0;
    box-shadow: none; }
  .block-1 {
    border: none;
    padding: 40px 7.3% 0;
    .wrap-block {
      padding: 0;
      .index__formWrapper {
        padding-bottom: 40px; } }
    & [class*=ic-] {
      padding: 0 10px; } }
  p {
    margin-bottom: 10px; }
  .block-2 {
    .controls {
      text-align: center;
      margin-top: 40px; } } }

.blue,
.iceberg {
  header {
    min-height: 530px; }
  .btn {
    padding: 0 37px;
    display: inline-block;
    height: 48px;
    line-height: 51px;
    border-radius: 10px;
    border: 0;
    box-shadow: 0.6px 2.9px 8.5px 0.5px rgba(0, 0, 0, 0.11), inset 0 0 10.4px 2.6px rgba(0, 0, 0, 0.23);
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    &:active {
      box-shadow: inset 0 0 10.4px 2.6px rgba(0, 0, 0, 0.23); } }
  .block-1 {
    position: relative;
    padding: 65px 7.3% 50px;
    .btn {
      margin-top: 40px; }
    .col-1 {
      padding-right: 70px;
      font-size: 20px;
      width: 58%;
      line-height: 1.2em; }
    .col-2 {
      width: 42%;
      text-align: left; }
    .row:before,.row:after {
      display: table;
      content: ''; }
    .row:after {
      clear: both; }
    .col-1,.col-2 {
      display: inline-block;
      float: left; }
    .image-preview {
      position: absolute;
      top: -11px; }
    .logo {
      position: absolute;
      top: -11px;
      left: 7.3%;
      min-width: 156px;
      height: 55px;
      text-align: left;
      &-image-helper {
        height: 100%;
        min-height: 55px;
        display: inline-block;
        vertical-align: bottom; }
      &-image {
        max-height: 55px;
        display: inline-block;
        vertical-align: bottom; } }
    .viber-btn {
      margin-left: 0;
      margin-top: 10px; } }
  p {
    font-size: 19px;
    margin-bottom: 10px !important; }
  ul, ol {
    font-size: 19px;
    li {
      padding-bottom: 10px; } }
  .block-2 {
    padding: 35px 0;
    [class*="ic-"] {
      width: 25%;
      display: inline-block;
      float: left;
      padding: 0 13px; } }
  .icon-box {
    margin-top: 40px;
    margin-bottom: 30px; }
  .block-4 {
    [class*="ic-"] {
      width: 33%;
      display: inline-block;
      float: left;
      padding: 0 13px; }
    .red {
      padding: 20px 0 62px 70px;
      width: 65%; } }
  .block-3 {
    min-height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 60px;
    .col-1 {
      padding-right: 40px; }
    .col-2 {
      padding-left: 100px;
      h1 {
        font-size: 42px !important; } }
    h6 {
      font-weight: normal;
      padding-bottom: 10px; } }
  .content-header {
    padding: 10px 60px; }
  footer {
    .col-2 {
      padding-left: 50px;
      text-align: left; }
    .btn {
      margin-bottom: 15px; }
    h6 {
      font-weight: normal; } } }

.ancor {
  font-family: 'Geometria', sans-serif;
  .ql-container {
    font-family: inherit; }
  .content {
    box-shadow: none;
    max-width: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between; }
  &.wrap {
    padding: 0; }
  .top-red {
    background-color: #f54d2e;
    padding: 10px 0;
    text-align: center;
    & > div {
      display: inline-block; } }
  .btn {
    height: 47px;
    padding: 0 25px;
    display: inline-block;
    line-height: 46px;
    color: #fff;
    border: 1px solid #fff;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    border-radius: 0;
    background-color: transparent;
    &.vacancy-btn-link {
      background: #f54d2e;
      border: none; }
    &:not(.viber-btn):hover {
      cursor: pointer;
      background: rgb(230, 43, 32); }
    &.viber-btn {
      border: none;
      margin-top: 1px;
      margin-left: 15px; } }
  header {
    padding: 0;
    background-color: #fff;
    min-height: auto;
    box-shadow: none;
    .row {
      position: relative;
      display: table;
      width: 100%; }
    .box-1 {
      display: table-cell;
      width: 46%; }
    .box-2 {
      display: table-cell;
      width: 54%; }
    .parallelogram {
      text-align: center;
      width: 54%;
      vertical-align: middle;
      .quill {
        display: inline-block;
        width: 100%; }
      .parallelogram-container {
        color: #fff;
        width: 100%;
        min-height: 300px;
        padding: 90px 30px 120px;
        position: relative;
        &:before {
          content: '';
          transform: skew(-30deg);
          background: #2b3d4f;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0; } }
      .parallelogram-content {
        max-width: 400px;
        width: 100%;
        display: inline-flex;
        z-index: 10;
        text-align: left;
        p {
          font-size: 30px !important; } } }
    .img-bg {
      content: '';
      min-height: 300px;
      background-size: cover;
      background-position: top;
      width: 57%;
      height: 100%;
      top: 0;
      position: absolute;
      display: block; }
    h1, h2, h3, h4, h5, h6,.html-block {
      color: inherit;
      position: relative;
      z-index: 10; } }
  .block-2 {
    ul {
      margin-bottom: 40px;
      padding-left: 20px;
      padding-right: 40px;
      font-size: 18px;
      line-height: 1.7em; }
    h1 {
      font-size: 28px !important; }
    h2 {
      font-size: 18px !important;
      font-weight: bold; }
    .wrap-block {
      padding: 0 30px;
      .html-block.content-header :first-child {
        margin: 1.8em 0 1.1em; } } }
  footer {
    margin-top: 60px;
    overflow: hidden;
    position: relative;
    .wrap-block {
      padding: 15px 30px; }
    &:before,
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      width: 50%;
      transform: skew(-30deg); }
    &:before {
      background-color: #f54d2e;
      left: 0; }
    &:after {
      background-color: #2b3d4f;
      float: right;
      right: 0;
      top: 0; } }
  .block-2 {
    margin: 0 auto;
    z-index: 9;
    color: black;
    max-width: 1020px;
    ul > li::before,
    .ql-editor ul > li:before {
      content: "-";
      margin-right: 5px;
      font-size: 16px;
      display: table-cell;
      padding-right: 8px;
      color: black; }
    li span {
      display: table-cell; }
    ul > li,
    .ql-editor ul > li {
      position: relative;
      &:before {
        position: absolute;
        left: -20px; } } }
  .row:before,.row:after {
    display: table;
    content: ''; }
  .row:after {
    clear: both; }
  .col-1,.col-2 {
    width: 50%;
    display: inline-block;
    float: left; }
  footer {
    background: transparent;
    .row {
      display: flex;
      max-width: 1020px;
      margin: 0 auto;
      .col-1,.col-2 {
        display: flex;
        align-self: center; } }
    a:not(.bot-link) {
      color: #fff;
      display: block;
      text-decoration: none;
      margin-top: 5px; }
    p {
      padding-bottom: 10px;
      font-size: 16px;
      &:first-child {
        padding-bottom: 0; } }
    .col-1 {
      color: black; }
    .col-2 {
      position: relative;
      & > span {
        left: 50%;
        z-index: 3;
        position: relative;
        transform: translateX(-50%); } } }
  .settings:last-child {
    display: flex;
    flex-direction: column-reverse; } }

.landing:not(.edit-mode) {
    .ancor header {
      overflow: hidden; } }

.preview-mode {
  .blue {
    .image-preview {
      position: relative; } }
  .ancor {
    header {
      overflow: hidden; } } }

.gray {
  .btn {
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: normal; }
  header {
    border-bottom-width: 7px;
    min-height: 529px;
    box-shadow: 0.6px 2.9px 32.9px 2.1px rgba(0,0,0,.2);
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: calc(100% - 7px);
      top: 0;
      left: 0;
      background: rgba(64,64,64,.62); } }
  .block-1 {
    padding: 65px 7.3% 55px;
    box-shadow: 0.6px 2.9px 32.9px 2.1px rgba(0,0,0,.2);
    border-bottom: 1px solid #e1e6f3;
    &-description {
      p {
        font-size: 22px; } } }
  .vacancies-block {
    padding: 50px 7.3%;
    position: relative;
    .vacancy-items {
      margin-top: 75px;
      .vacancy-item {
        text-align: left;
        margin-bottom: 70px;
        &-apply-btn {
          display: inline-flex; }
        &-header {
          display: flex; }
        &-title {
          margin-bottom: 30px;
          * {
            font-weight: 300; } }
        .btn {
          font-size: 14px;
          padding: 0 20px;
          line-height: 48px;
          height: 48px;
          &-view {
            margin-right: 15px; } } } }
    .btn-add-vacancy {
      color: #555;
      display: none;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      padding: 0 20px; }
    .btn-remove-vacancy {
      background: #fff;
      margin-left: 15px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      padding: 5px 8px;
      margin-top: 5px;
      transition: background .3s;
      display: none;
      border: none;
      cursor: pointer;
      &:hover {
        background: #efefef; } } } }

.edit-mode {
  .gray {
    .vacancies-block:hover {
      .btn-add-vacancy,
      .btn-remove-vacancy {
        display: inline-block; } } } }

.vacancy-item-modal {
  .ant-modal-body {
    padding: 0;
    p {
      font-size: 18px;
      margin-bottom: 5px; }
    ul {
      font-size: 18px; } }
  &-title {
    * {
      font-size: 22px !important;
      color: #000 !important;
      font-weight: normal; } } }

@media screen and (max-width: 1140px) {
  .edit-mode, .landing {
    .wrap {
        padding-bottom: 0; } } }


@media screen and (max-width: 900px) {
  .btn {
    padding: 0 20px;
    font-size: 16px; }
  .ancor {
    @import 'ancorMax990'; } }

@media screen and (min-width: 768px) {
  header {
    .btn {
      margin-top: 25px; } }
  .red {
    header {
      min-height: 550px; }
    .btn {
      height: 50px;
      line-height: 50px;
      font-size: 16px; } }
  .green {
    p,ol,ul {
      font-size: 18px; } } }

@media screen and (max-width: 767px) {
  @import 'small';
  .blue,
  .iceberg {
    @import 'smallBlue'; }
  .ancor {
    @import 'ancorMax767'; }
  h1 {
    font-size: 28px; }
  h2 {
    font-size: 24px; }
  h3 {
    font-size: 20px; }
  h4 {
    font-size: 18px; }
  h5 {
    font-size: 16px; }
  h6 {
    font-size: 14px; }
  header {
    display: block;
    padding: 0 10px 130px; }
  header .btn-main-container,.header-content {
    display: block;
    text-align: center;
    .logo {
      padding-top: 15px;
      margin-bottom: 0; } }
  .green {
    .bg-grey {
      padding-bottom: 20px; }
    p {
      font-size: 18px; } }
  .red {
    &.wrap ul {
      font-size: 14px; } } }

@media screen and (max-width: 600px) {
  .ancor {
    @import 'ancorMax600'; } }

@import 'themes';

.preview {
  .preview-img {
    width: 340px;
    height: 500px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    background: center 0% / cover no-repeat rgb(170, 170, 170);
    transition-duration: 1s;
    &:hover {
      background-position: center 100%;
      transition: background-position 2s cubic-bezier(0, 0.2, 0.2, 0); } } }
.clear {
  &:before,&:after {
    content: '';
    display: table; }
  &:after {
    clear: both; } }
.modal-template {
  background: #fff;
  height: 100vh;
  top: 0 !important;
  width: 100% !important;
  margin: 0;
  padding: 0 !important;
  overflow: auto;
  min-width: 600px;
  position: relative;
  padding-bottom: 0 !important;
  .ant-modal-content {
    height: 100%;
    box-shadow: none; }
  .ant-modal-body {
    padding: 0; }
  .ant-modal-close {
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: fixed;
    top: 30px;
    right: 40px;
    &:focus,&:hover {
      color: #40a9ff;
      border-color: #40a9ff; }
    &:active {
      color: #096dd9;
      border-color: #096dd9; }
    &-x {
      height: 50px;
      width: 50px;
      line-height: 50px;
      font-size: 25px; } }
  &-body {
    padding: 30px;
    &-preview {
      vertical-align: top;
      display: inline-block;
      background-color: #f7f7f7;
      width: 70%;
      min-height: 200px; }
    &-desc {
      padding-left: 30px;
      display: inline-block;
      width: 30%;
      position: fixed;
      h2 {
        margin: 15px 0;
        font-size: 1.1rem !important;
        text-transform: uppercase;
        color: #72848c;
        line-height: 1.6;
        font-weight: normal; }
      .controls {
        button {
          height: 50px;
          width: 50px;
          font-size: 24px;
          margin-right: 10px; } } } }
  .close {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    outline: none;
    right: 10px;
    position: relative;
    i {
      color: #72848c;
      font-size: 40px; } }
  .themes-select {
    .theme-img {
      margin: 0 1% 0 0; } } }

.ant-select-dropdown-menu {
  padding: 0 !important;
  li {
    padding-left: 12px !important; } }

.settings-template-button {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 10px;
  font-size: 14px;
  background: #008ED6;
  border-radius: 1px;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  color: white;
  margin: 8px 0 12px;
  cursor: pointer;
  border: none; }

.viber-btn {
  overflow: hidden;
  display: inline-block;
  color: #fff;
  background: #59267C;
  &:hover {
    color: #fff;
    background: #4b1e69; }
  &:focus {
    text-decoration: none; }
  i {
    position: relative;
    top: 5px;
    font-size: 27px; } }
.settings-toolbar-wrapper {
  overflow: hidden;
  text-align: center;
  .settings-toolbar {
    text-align: left; }
  .ant-select-selection-selected-value {
    padding-left: 10px; }
  .ant-select-arrow {
    right: 10px; } }
.modal-template-body-preview {
  overflow: auto;
  height: calc(100vh - 30px);
  .preview-mode {
    display: block;
    overflow: hidden; } }
.select-description {
  display: block;
  color: #999; }

.custom-scroll-content {
  .custom-scroll,
  .outer-container,
  .inner-container {
    height: 100%; } }

.ql-tooltip-editor {
  input {
    padding: 10px 40px 10px 20px !important; }
  .toolbar-complete-link-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    right: 5px;
    top: calc(50% - 15px);
    &:before {
      content: '\E632';
      font-family: anticon; } } }

.quill {
  min-width: 50px; }

@import 'form';

.ql-toolbar:not(.ql-snow) {
  text-align: center;
  position: fixed;
  top: -56px;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 0;
  transition: top .3s, opacity 0s linear .4s;
  &.show {
    top: 0;
    opacity: 1;
    transition: top .3s, opacity 0s linear 0s;
    z-index: 1001;
    & + div {
      background: transparentize(#000, .95);
      transition: background .3s; } }
  .ql-toolbar-wrapper {
    background: #fff;
    height: 56px;
    user-select: none;
    button {
      height: 56px;
      width: 56px;
      border: none;
      background: none;
      cursor: pointer;
      &:hover {
        svg {
          color: #2d89db; } }
      svg {
        color: rgba(0, 0, 0, 0.65); }
      &.ql-active {
        position: relative;
        &:before {
          position: absolute;
          content: '';
          height: 3px;
          left: 0;
          bottom: 0;
          right: 0;
          background: #2d89db; } } } }
  .ql-select {
    position: relative;
    display: inline-block;
    height: 100%;
    .dropdown-content {
      position: absolute;
      left: 0;
      box-shadow: 0 6px 7px rgba(0, 0, 0, 0.06);
      max-height: 295px;
      overflow-y: hidden;
      &-with-scroll {
        height: 295px; }
      button {
        height: auto;
        width: auto;
        min-width: 56px;
        background: #fff;
        padding: 10px 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        white-space: pre;
        width: 100%;
        color: rgba(0, 0, 0, 0.65);
        &.text {
          text-align: left; }
        &.selected {
          background: lighten(#000, 93%); } } } }
  .ql-color-select {
    position: relative;
    display: inline-block;
    height: 100%;
    .dropdown-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 6px 7px rgba(0, 0, 0, 0.06);
      .sketch-picker {
        & > .flexbox-fix:nth-child(3) {
          color: #000; } } } }
  .ql-link {
    position: relative;
    display: inline-block;
    height: 100%;
    .dropdown-content {
      position: absolute;
      background: #fff;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
      border-radius: 4px;
      width: 170px;
      input {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.07);
        width: calc(100% - 26px);
        transition: all .3s;
        padding: 0 7px;
        color: #000;
        &:hover,
        &:focus {
          border-color: #2d89db;
          outline: none; } }
      button {
        width: auto;
        height: auto;
        display: inline-block;
        margin-left: 7px; } } } }


.ant-dropdown-menu {
  padding-left: 0 !important; }

.ant-modal-wrap {
  z-index: 2000 !important; }

.ant-modal-mask {
  z-index: 2000 !important; }

.readonly-highlight,
.readonly-highlight-end {
  background-color: gray;
  opacity: 0.1;
  position: absolute; }

.react-confirm-alert-overlay {
  z-index: 3000; }

.react-confirm-alert-button-group button {
  background: #1890ff;
  font-size: 14px; }
