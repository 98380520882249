header {
    min-height: 315px;
    p,h1,h2,h3,h4,h5,h6 {
        padding: 25px 20px; } }
.block-1 {
    padding: 65px 20px 50px;
    .logo {
        top: -8px;
        left: 20px;
        &-image {
            max-height: 50px;
            background-size: 150px 50px; } }
    .image-preview {
      height: 55px;
      position: absolute;
      top: -11px;
      left: 20px; }
    .col-1,
    .col-2 {
        width: 100%;
        padding: 0; } }
p {
    font-size: 15px; }
h1 {
    font-size: 28px; }
h2 {
    font-size: 26px; }
h3 {
    font-size: 24px; }
h4 {
    font-size: 22px; }
h5 {
    font-size: 20px; }
h6 {
    font-size: 18px; }
ul, ol {
    font-size: 15px; }
[class*="ic-"] {
    width: 100% !important;
    margin: 5px; }
.icon-box {
    margin: 0; }
.content-header {
    padding: 10px 20px; }
.block-3 {
    min-height: 425px;
    padding: 30px 20px;
    .col-1,
    .col-2 {
        float: none;
        padding: 0; } }
.block-4 {
    .red {
        padding: 20px 20px;
        width: 100%; } }
footer {
    .col-1 {
        width: 100%; }
    .col-2 {
        width: 100%;
        padding-left: 0; } }

.header-content {
    .ql-editor {
        h4 {
            padding: 0 20px;

            &:first-of-type {
                padding-top: 25px; }
            &:last-of-type {
                padding-bottom: 25px; } } }

    .html-block {
        h4 {
            padding: 0 20px;

            &:first-of-type {
                padding-top: 25px; }
            &:last-of-type {
                padding-bottom: 25px; } } } }
