header {
    .img-bg {
        width: 65%; }
    .parallelogram .parallelogram-container {
        padding: 90px 30px 120px 50px;
        h1 {
            font-size: 30px; } } }
.btn {
    font-size: 14px;
    padding: 0 20px;
    background: transparent;
    box-shadow: none; }
footer .col-1 {
    text-align: center;
    * {
        text-align: center; } }
