.block-2 ul {
    font-size: 14px !important; }
header .btn {
    margin-bottom: 30px; }
header .parallelogram .parallelogram-container {
    padding: 30px 20px 50px;
    line-height: 1.5em;
    z-index: 3;
    position: relative; }
.btn {
    font-size: 13px;
    padding: 0 20px;
    margin: 0; }
footer .btn {
    margin: 15px 0; }
footer p {
    font-size: 14px !important;
    line-height: 1.3em; }
footer a:not(.bot-link) {
    white-space: nowrap;
    margin: 0; }
footer .col-2 {
    padding-top: 0; }
header {
    min-height: inherit; }
