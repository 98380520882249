.icon-telegram:before {
  content: "\e90a"; }

.icon-viber:before {
  content: "\e90b"; }

.icon-heart:before {
  content: "\e908"; }

.icon-check-circle2:before {
  content: "\e909"; }

.icon-check-circle:before {
  content: "\e900"; }

.icon-check-square:before {
  content: "\e901"; }

.icon-radio_unchecked:before {
  content: "\e902"; }

.icon-radio_checked:before {
  content: "\e903"; }

.icon-check_circle:before {
  content: "\e904"; }

.icon-check_box:before {
  content: "\e905"; }

.icon-check:before {
  content: "\e906"; }

.icon-check-bold:before {
  content: "\e907"; }
