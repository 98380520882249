@font-face {
  font-family: 'Icon';
  src:  url('../assets/fonts/Icon.eot?wt0kbf');
  src:  url('../assets/fonts/Icon.eot?wt0kbf#iefix') format('embedded-opentype'),
  url('../assets/fonts/Icon.ttf?wt0kbf') format('truetype'),
  url('../assets/fonts/Icon.woff?wt0kbf') format('woff'),
  url('../assets/fonts/Icon.svg?wt0kbf#Icon') format('svg') {}
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url('../assets/fonts/Geometria.eot');
  src: url('../assets/fonts/Geometria.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Geometria.woff') format('woff'),
  url('../assets/fonts/Geometria.ttf') format('truetype') {}
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Geometria';
  src: url('../assets/fonts/Geometria-Medium.eot');
  src: url('../assets/fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Geometria-Medium.woff') format('woff'),
  url('../assets/fonts/Geometria-Medium.ttf') format('truetype') {}
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/fonts/fontawesome-webfont.ttf');
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
