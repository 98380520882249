h2 {
    text-align: center; }
header .box-2 {
    padding-bottom: 0; }
header .row, header .box-1 {
    display: block; }
header .box-1 {
    width: 100%; }
header .parallelogram .parallelogram-container {
    min-height: 200px;
    padding: 60px 20px 90px;
    &:before {
        display: none; }
    .parallelogram-content {
        text-align: center;
        max-width: inherit;
        & > div {
            width: 100%; } } }
header .parallelogram {
    width: 100%;
    display: block; }
header .row .box-1 .img-bg {
    width: 100%;
    min-height: 200px;
    background-position: top;
    &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.38);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; } }
.block-2 {
    padding: 0 10px;
    ul {
        font-size: 18px; }
    .wrap-block {
        padding: 0; } }
footer .col-1 {
    padding-top: 10px; }
footer p {
    font-size: 16px !important;
    text-align: center; }
.block-2 ul {
    padding: 0 20px 0 30px !important; }
.col-1, .col-2 {
    text-align: center;
    width: 100%; }
footer:before {
    left: 0;
    right: 0;
    width: 100%; }
footer:after {
    display: none; }
footer .col-2 {
    & > span {
        position: static;
        transform: none; } }
footer .row {
    display: block;
    .col-1,.col-2 {
        display: block; } }
.btn {
    margin-top: 2px; }
